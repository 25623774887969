
#list-Card {
    display: flex;
    align-items: baseline;
    width: auto;
    justify-content: space-between;
    color: white;
    margin-right: 45px;
}

#list-Card .image-list{
   height: 27px;
   margin-right: 10px
}
#list-Card .number-list {
    font-size: 40px;
    font-weight: 400;
     margin-right: 10px
}
#list-Card .ton-list{
    font-size: 22px;
}


