body {
  font-family: 'Poppins', sans-serif;
}
.whether-container{
  /* background-image: url(https://st.depositphotos.com/1000641/1387/i/600/depositphotos_13876018-stock-photo-cloud-and-blue-sky.jpg); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background: #2196f380;
  display: flex;
  height: 100%;
}
.degree-wrapper {
  color: white;
  background: #ffffff1c;
  text-align: center;
  padding: 10px 0;
  width: 25%;
  /* border: 1px solid #eee */
}
.degree-wrapper img{
   position: absolute;
   right: 0;
   top: 0;
   z-index: -1;
}
.degree {
  position: absolute;
  top: 0px;
  color: white;
  text-shadow: 2px 2px 2px #bbbbbb;
  font-size: 20px;
}
.degree-value{
  position: relative;
  font-size: 50px;
  text-shadow: 2px 2px 2px #bbbbbb;
  color: white;
  font-weight: bold;
}
.status-bar{
  background: #90badacc;
  padding: 6px;
  font-size: 15px;
  width: 50%;
  margin: 10px auto;
  border-radius: 20px;
  color: #222225;
  font-weight: bold;
}
.time-wrapper{
  position: relative;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 75%
}
.clock {
  border: 1px solid #eee;
  border-radius: 40px;
  background: #1514140f;
}
.clock :first-child :first-child :first-child :first-child {
  /* display: none */
  border: none;

}
.time{
  font-size: 40px;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 2px 2px 2px #bbbbbb;
  
}
.location{
  display: flex;
  padding: 10px;
  align-items: center;
}

.location img{
  padding: 10px;
}
.location h3{
  margin: 0;
  color: #696868
}

.location p{
 margin: 0;
 padding: 0;
 color: #828282
}
.map-img{
  width: 100%;
  padding: 5px;
}

a{
  text-decoration: none !important;
}

.MuiAppBar-root{
  background-color: #6b6b6b !important
}

.map-view {
  /* background-image: url('./icons/Kevins Design Header.png'); */
  background-size: cover;
  background-position: center;
  position: relative;
  border-radius: 10px;
    margin: 0px 20px;
}

.map-view img {
  width: 100%
}

.MuiTypography-body1 {
  font-weight: bold;
  font-size: 2rem !important;
  color: #776868;
  font-family: 'Poppins', sans-serif;
  font-weight: bold !important;
}
.solar-chart-card-container{
  margin-bottom: 30px;
}
.solar-chart-card{
  /* margin-bottom: 30px; */
  background: #ffffff;
  padding-top: 20px;

  /* box-shadow: 0px 100px 80px rgba(108, 73, 172, 0.07), 0px 41.7776px 33.4221px rgba(108, 73, 172, 0.0503198), 0px 22.3363px 17.869px rgba(108, 73, 172, 0.0417275), 0px 12.5216px 10.0172px rgba(108, 73, 172, 0.035), 0px 6.6501px 5.32008px rgba(108, 73, 172, 0.0282725), 0px 2.76726px 2.21381px rgba(108, 73, 172, 0.0196802); */
  border-radius: 10px !important;
}

.chart-area {
  height: calc(74vh);
  background: #4f4f4f;
}

.date-time {
  position: absolute;
    bottom: 0;
    padding: 20px 42px;
    font-size: 40px;
    color: white;
    text-shadow: 2px 2px 2px #bbbbbb;
    background: #9ebff545;
    margin: 5px;
    font-weight: bold;
}

@media only screen and (max-width: 768px) {
  .degree-value {
    position: relative;
    font-size: 20px;
    text-shadow: 2px 2px 2px #bbbbbb;
    color: white;
    font-weight: bold;
  }

  .whether-icon {
    width: 50px;
    margin-left: 20px
  }

  .status-bar{
    font-size: 10px;
  }

  .MuiTypography-body1 {
    font-size: 14px !important;
  }
}
.highcharts-title{
  display: none;
}