// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');

#power-list .power-card{
    border-radius: 10px;
    background: #FFFFFF;
    padding: 30px;
    margin-bottom: 30px;
}

#power-list .title{
  font-size: 24px;
  color: #b00505;
  font-weight: bolder !important;
  font-family:'Montserrat' !important;
}

#power-list .label{
   font-size: 47px;
font-weight: 400;
}
#power-list .img-style{
   height: 60px;
}

#power-list .card-flex-box{
    display: flex;
    align-items: baseline;
}
#power-list .title-Icon{
  height: 20px;
  margin-right: 7px;
}

#power-list .top-flex-box{
    display: flex;
    align-items: baseline;
}

#power-list .bottom-flex-box{
  display: flex;
  justify-content: space-between;
  width: 250px;
}

#power-list .bottom-text{
  font-size: 13px
}

#power-list .bottom-value{
  font-size: 14px
}

#power-list .bottom-unit{
  font-size: 12px
}

#power-list .line-divider{
      height: 1px;
    background: #dadada;
    margin-bottom: 10px;
}

#power-list .left-container{
  width: -webkit-fill-available;
}