@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap');

body {
  margin: 0;
  font-family:'Montserrat' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.highcharts-container {
    border-radius: 10px !important
}
.highcharts-credits{
  display: none;
}
.highcharts-legend-item {
  display: none;
}