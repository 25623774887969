#weather-card {
    border-radius: 10px;
    background: linear-gradient(-50deg, #dfdfdf, white);
    padding: 40px 30px;
        margin-bottom: 30px;
}


#weather-card .temp{
    font-size: 60px;
    font-weight: 400;
    position: relative
}

#weather-card .degree-temp{
    font-size: 24px;
    font-weight: 400;
    position: absolute;
    top: 10px;
}
#weather-card img{
   height: 100px;
}

#weather-card .status{
   line-height: 20px;
   text-transform: uppercase;
}

#weather-card .weather-flex-box{
    display: flex;
    justify-content: space-between;
    align-items: flex-end
}
#weather-card .weather-card-time{
   font-size: 45px;
}
#weather-card .weather-card-ampm{
font-size: 30px;
}
#weather-card .weather-card-date{
font-size: 18px;
text-transform: uppercase;
}
#weather-card .weather-card-year{
font-size: 18px;
}

#weather-card .time-container{
    text-align-last: center;
}

#weather-card .divider{
    height: 2px;
    background: white;
    width: 50%;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 25px;
}
