@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap);
body {
  margin: 0;
  font-family:'Montserrat' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.highcharts-container {
    border-radius: 10px !important
}
.highcharts-credits{
  display: none;
}
.highcharts-legend-item {
  display: none;
}
.energy-card-icon{padding:10px;max-width:65px}.energy-card{padding:5px;display:flex;align-items:center;box-shadow:0px 100px 80px rgba(108,73,172,.07),0px 41.7776px 33.4221px rgba(108,73,172,.0503198),0px 22.3363px 17.869px rgba(108,73,172,.0417275),0px 12.5216px 10.0172px rgba(108,73,172,.035),0px 6.6501px 5.32008px rgba(108,73,172,.0282725),0px 2.76726px 2.21381px rgba(108,73,172,.0196802);border-radius:10px;background:#fff}.one-third-width{margin:20px 5px;width:calc(33% - 15px)}@media only screen and (max-width: 768px){.energy-card-icon{max-width:50px}.energy-card h1{font-size:16px !important}.energy-card .title{font-size:12px !important}}.energy-card h1{font-family:Poppins;font-style:normal;font-weight:600;font-size:30px;color:#383874;margin:0;padding:0}.energy-card .title{font-family:Poppins;font-style:normal;font-weight:300;font-size:20px;line-height:24px;color:#383874}.cards-container{height:-webkit-fill-available;display:flex;align-items:flex-start;justify-content:center;position:relative;max-width:100%;padding:20px}.children-wrapper{position:absolute;left:0;right:0;padding:20px;background:#4f4f4f}#list-Card{display:flex;align-items:baseline;width:210px;justify-content:space-between;color:#fff}.flex-box{display:flex;margin:20px;margin-top:0px;border-bottom:2px solid #fff;padding-bottom:20px;width:-webkit-min-content;width:min-content;margin-bottom:35px}.title-chart{color:#fff;font-size:25px;font-weight:bold;padding-bottom:10px}
#power-list .power-card{border-radius:10px;background:#fff;padding:30px;margin-bottom:30px}#power-list .title{font-size:24px;color:#b00505;font-weight:bolder !important;font-family:"Montserrat" !important}#power-list .label{font-size:47px;font-weight:400}#power-list .img-style{height:60px}#power-list .card-flex-box{display:flex;align-items:baseline}#power-list .title-Icon{height:20px;margin-right:7px}#power-list .top-flex-box{display:flex;align-items:baseline}#power-list .bottom-flex-box{display:flex;justify-content:space-between;width:250px}#power-list .bottom-text{font-size:13px}#power-list .bottom-value{font-size:14px}#power-list .bottom-unit{font-size:12px}#power-list .line-divider{height:1px;background:#dadada;margin-bottom:10px}#power-list .left-container{width:-webkit-fill-available}
body {
  font-family: 'Poppins', sans-serif;
}
.whether-container{
  /* background-image: url(https://st.depositphotos.com/1000641/1387/i/600/depositphotos_13876018-stock-photo-cloud-and-blue-sky.jpg); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background: #2196f380;
  display: flex;
  height: 100%;
}
.degree-wrapper {
  color: white;
  background: #ffffff1c;
  text-align: center;
  padding: 10px 0;
  width: 25%;
  /* border: 1px solid #eee */
}
.degree-wrapper img{
   position: absolute;
   right: 0;
   top: 0;
   z-index: -1;
}
.degree {
  position: absolute;
  top: 0px;
  color: white;
  text-shadow: 2px 2px 2px #bbbbbb;
  font-size: 20px;
}
.degree-value{
  position: relative;
  font-size: 50px;
  text-shadow: 2px 2px 2px #bbbbbb;
  color: white;
  font-weight: bold;
}
.status-bar{
  background: #90badacc;
  padding: 6px;
  font-size: 15px;
  width: 50%;
  margin: 10px auto;
  border-radius: 20px;
  color: #222225;
  font-weight: bold;
}
.time-wrapper{
  position: relative;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 75%
}
.clock {
  border: 1px solid #eee;
  border-radius: 40px;
  background: #1514140f;
}
.clock :first-child :first-child :first-child :first-child {
  /* display: none */
  border: none;

}
.time{
  font-size: 40px;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 2px 2px 2px #bbbbbb;
  
}
.location{
  display: flex;
  padding: 10px;
  align-items: center;
}

.location img{
  padding: 10px;
}
.location h3{
  margin: 0;
  color: #696868
}

.location p{
 margin: 0;
 padding: 0;
 color: #828282
}
.map-img{
  width: 100%;
  padding: 5px;
}

a{
  text-decoration: none !important;
}

.MuiAppBar-root{
  background-color: #6b6b6b !important
}

.map-view {
  /* background-image: url('./icons/Kevins Design Header.png'); */
  background-size: cover;
  background-position: center;
  position: relative;
  border-radius: 10px;
    margin: 0px 20px;
}

.map-view img {
  width: 100%
}

.MuiTypography-body1 {
  font-weight: bold;
  font-size: 2rem !important;
  color: #776868;
  font-family: 'Poppins', sans-serif;
  font-weight: bold !important;
}
.solar-chart-card-container{
  margin-bottom: 30px;
}
.solar-chart-card{
  /* margin-bottom: 30px; */
  background: #ffffff;
  padding-top: 20px;

  /* box-shadow: 0px 100px 80px rgba(108, 73, 172, 0.07), 0px 41.7776px 33.4221px rgba(108, 73, 172, 0.0503198), 0px 22.3363px 17.869px rgba(108, 73, 172, 0.0417275), 0px 12.5216px 10.0172px rgba(108, 73, 172, 0.035), 0px 6.6501px 5.32008px rgba(108, 73, 172, 0.0282725), 0px 2.76726px 2.21381px rgba(108, 73, 172, 0.0196802); */
  border-radius: 10px !important;
}

.chart-area {
  height: calc(74vh);
  background: #4f4f4f;
}

.date-time {
  position: absolute;
    bottom: 0;
    padding: 20px 42px;
    font-size: 40px;
    color: white;
    text-shadow: 2px 2px 2px #bbbbbb;
    background: #9ebff545;
    margin: 5px;
    font-weight: bold;
}

@media only screen and (max-width: 768px) {
  .degree-value {
    position: relative;
    font-size: 20px;
    text-shadow: 2px 2px 2px #bbbbbb;
    color: white;
    font-weight: bold;
  }

  .whether-icon {
    width: 50px;
    margin-left: 20px
  }

  .status-bar{
    font-size: 10px;
  }

  .MuiTypography-body1 {
    font-size: 14px !important;
  }
}
.highcharts-title{
  display: none;
}
#weather-card{border-radius:10px;background:linear-gradient(-50deg, #dfdfdf, white);padding:40px 30px;margin-bottom:30px}#weather-card .temp{font-size:60px;font-weight:400;position:relative}#weather-card .degree-temp{font-size:24px;font-weight:400;position:absolute;top:10px}#weather-card img{height:100px}#weather-card .status{line-height:20px;text-transform:uppercase}#weather-card .weather-flex-box{display:flex;justify-content:space-between;align-items:flex-end}#weather-card .weather-card-time{font-size:45px}#weather-card .weather-card-ampm{font-size:30px}#weather-card .weather-card-date{font-size:18px;text-transform:uppercase}#weather-card .weather-card-year{font-size:18px}#weather-card .time-container{text-align-last:center}#weather-card .divider{height:2px;background:#fff;width:50%;margin:auto;margin-top:40px;margin-bottom:25px}
#list-Card{display:flex;align-items:baseline;width:auto;justify-content:space-between;color:#fff;margin-right:45px}#list-Card .image-list{height:27px;margin-right:10px}#list-Card .number-list{font-size:40px;font-weight:400;margin-right:10px}#list-Card .ton-list{font-size:22px}
