.energy-card-icon{
    padding: 10px;
    max-width: 65px;
   
}

.energy-card{
    padding: 5px;
    display: flex;
    align-items: center;
    box-shadow: 0px 100px 80px rgba(108, 73, 172, 0.07), 0px 41.7776px 33.4221px rgba(108, 73, 172, 0.0503198), 0px 22.3363px 17.869px rgba(108, 73, 172, 0.0417275), 0px 12.5216px 10.0172px rgba(108, 73, 172, 0.035), 0px 6.6501px 5.32008px rgba(108, 73, 172, 0.0282725), 0px 2.76726px 2.21381px rgba(108, 73, 172, 0.0196802);
    border-radius: 10px;
    background: #FFFFFF;
    // padding: 20px 30px;
    
}

.one-third-width{
    margin: 20px 5px;
    width: calc(33% - 15px)
}

@media only screen and (max-width: 768px) {
    .energy-card-icon {
        max-width: 50px;
    }

    .energy-card h1{ 
        font-size: 16px !important;
    }

    .energy-card .title{  
        font-size: 12px !important;
    }
}

.energy-card h1{ 
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    color: #383874;
    margin: 0;
    padding: 0;
}

.energy-card .title{ 
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    color: #383874;
}



.cards-container {
    height: -webkit-fill-available;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    max-width: 100%;
    padding: 20px;
}
.children-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    padding: 20px;
    background: #4f4f4f;
}

/////////////////////////@at-root

#list-Card {
       display: flex;
    align-items: baseline;
    width: 210px;
    justify-content: space-between;
    color: white;
}

.flex-box{
   display: flex;
    margin: 20px;
    margin-top: 0px;
    border-bottom: 2px solid white;
    padding-bottom: 20px;
    width: min-content;
    margin-bottom: 35px;

}

.title-chart{
    color: white;
    font-size: 25px;
    font-weight: bold;
    padding-bottom: 10px;
}

// path{
//     stroke: yellow
// }
